import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { renderLayerToPng } from './LayerSection/renderLayerToPng';
import { renderLayerToSVG } from './LayerSection/renderLayerToSVG';

const LayerContext = createContext();

export const useLayerManager = () => useContext(LayerContext);

export const LayerManager = ({ children }) => {
  const [layers, setLayersState] = useState([]);
  const [layerPreviews, setLayerPreviews] = useState({});
  const [geometryUpdateQueue, setGeometryUpdateQueue] = useState([]);
  const [simpleUpdateQueue, setSimpleUpdateQueue] = useState([]);
  const [activeLayer, setActiveLayer] = useState(null);
  const visibleLayersRef = useRef([]);
  const updateTimeoutRef = useRef({});
  const editingSizeRef = useRef({ width: 0, height: 0 });
  const previewCallbacksRef = useRef({});

  const updateVisibleLayers = useCallback(() => {
    visibleLayersRef.current = layers.filter((layer) => layer.isVisible);
    console.log('Updated visible layers:', visibleLayersRef.current);
  }, [layers]);

  useEffect(() => {
    updateVisibleLayers();
  }, [layers, updateVisibleLayers]);

  const clearGeometryUpdateQueue = useCallback(() => {
    setGeometryUpdateQueue([]);
  }, []);

  const clearSimpleUpdateQueue = useCallback(() => {
    setSimpleUpdateQueue([]);
  }, []);

  const updateLayerSvg = useCallback((layerId) => {
    setLayerPreviews((prev) => {
      const layerPreview = prev[layerId];
      if (layerPreview && layerPreview.png) {
        renderLayerToSVG(
          layerPreview.png,
          editingSizeRef.current.width,
          editingSizeRef.current.height
        )
          .then((svgString) => {
            console.log('SVG rendered successfully for layerId:', layerId);
            setLayerPreviews((current) => {
              const updated = {
                ...current,
                [layerId]: { ...current[layerId], svg: svgString },
              };
              if (previewCallbacksRef.current[layerId]) {
                previewCallbacksRef.current[layerId]();
                delete previewCallbacksRef.current[layerId];
              }
              return updated;
            });
            // Add to geometryUpdateQueue only after SVG is ready
            setGeometryUpdateQueue((current) => [...current, layerId]);
          })
          .catch((error) => {
            console.error('Error in renderLayerToSVG:', error);
          });
      } else {
        console.warn('No PNG data found for layerId:', layerId);
      }
      return prev;
    });
  }, []);

  const updateLayerPng = useCallback(
    (layerData) => {
      renderLayerToPng(
        layerData,
        editingSizeRef.current.width,
        editingSizeRef.current.height
      )
        .then((pngDataUrl) => {
          console.log('PNG rendered successfully for layerId:', layerData.id);
          setLayerPreviews((prev) => ({
            ...prev,
            [layerData.id]: { ...prev[layerData.id], png: pngDataUrl },
          }));
          updateLayerSvg(layerData.id);
        })
        .catch((error) => {
          console.error('Error in renderLayerToPng:', error);
        });
    },
    [updateLayerSvg]
  );

  const debouncedUpdateLayerPreview = useCallback(
    (layerData, onPreviewReady) => {
      console.log(
        'debouncedUpdateLayerPreview called for layerId:',
        layerData.id
      );
      if (updateTimeoutRef.current[layerData.id]) {
        clearTimeout(updateTimeoutRef.current[layerData.id]);
      }

      if (onPreviewReady) {
        previewCallbacksRef.current[layerData.id] = onPreviewReady;
      }

      updateTimeoutRef.current[layerData.id] = setTimeout(() => {
        updateLayerPng(layerData);
      }, 500);
    },
    [updateLayerPng]
  );

 const setLayers = useCallback(
   (layersOrUpdater) => {
     console.log('setLayers called');
     setLayersState((prevLayers) => {
       const newLayers =
         typeof layersOrUpdater === 'function'
           ? layersOrUpdater(prevLayers)
           : layersOrUpdater;

       newLayers.forEach((newLayer, index) => {
         const prevLayer = prevLayers.find((layer) => layer.id === newLayer.id);
         if (prevLayer) {
           const hasGeometryChange =
             JSON.stringify(omit(newLayer, ['name', 'isVisible'])) !==
             JSON.stringify(omit(prevLayer, ['name', 'isVisible']));

           if (hasGeometryChange) {
             console.log('Geometry change detected in layer:', newLayer.id);
             if (newLayer.isVisible) {
                //console.log("1")
               debouncedUpdateLayerPreview(newLayer);
             }
           } else if (newLayer.isVisible !== prevLayer.isVisible) {
             console.log(
               `Visibility change detected in layer: ${newLayer.id} to ${newLayer.isVisible}`
             );
             if (newLayer.isVisible) {
                console.log('2');
               // Layer has become visible, trigger an update
               debouncedUpdateLayerPreview(newLayer);
               setGeometryUpdateQueue((current) => [...current, newLayer.id]);
             }
           }
         } else {
           console.log('New layer detected:', newLayer.id);
           if (newLayer.isVisible) {
             debouncedUpdateLayerPreview(newLayer);
           }
         }
       });

       //updateVisibleLayers();
       return newLayers;
     });
   },
   [debouncedUpdateLayerPreview]
 );

  const addLayer = useCallback(
    (newLayer, onPreviewReady) => {
      console.log('Adding new layer:', newLayer);
      setLayersState((prevLayers) => [newLayer, ...prevLayers]);
      setActiveLayer(newLayer.id);
      debouncedUpdateLayerPreview(newLayer, onPreviewReady);
    },
    [debouncedUpdateLayerPreview]
  );

  const duplicateLayer = useCallback(
    (layerId) => {
      console.log('Duplicating layer:', layerId);
      setLayersState((prevLayers) => {
        const layerToDuplicate = prevLayers.find(
          (layer) => layer.id === layerId
        );
        if (!layerToDuplicate) {
          console.warn('Layer not found for duplication:', layerId);
          return prevLayers;
        }

        const newLayer = {
          ...layerToDuplicate,
          id: `duplicate-${Date.now()}-${layerId}`,
          name: `${layerToDuplicate.name} (Copy)`,
          segments: layerToDuplicate.segments.map((segment) => ({
            ...segment,
            id: `${segment.id}-duplicate-${Date.now()}`,
          })),
        };

        const updatedLayers = [newLayer, ...prevLayers];
        debouncedUpdateLayerPreview(newLayer);
        return updatedLayers;
      });

      setActiveLayer(`duplicate-${Date.now()}-${layerId}`);
    },
    [debouncedUpdateLayerPreview]
  );

  const deleteLayer = useCallback(
    (layerId) => {
      console.log('Deleting layer:', layerId);
      setLayersState((prevLayers) =>
        prevLayers.filter((layer) => layer.id !== layerId)
      );
      setLayerPreviews((prev) => {
        const { [layerId]: deleted, ...rest } = prev;
        return rest;
      });
      setSimpleUpdateQueue((current) => [...current, 'delete-' + layerId]);

      if (activeLayer === layerId) {
        setLayersState((prevLayers) => {
          const newActiveLayer = prevLayers[0]?.id || null;
          setActiveLayer(newActiveLayer);
          return prevLayers;
        });
      }

      if (updateTimeoutRef.current[layerId]) {
        clearTimeout(updateTimeoutRef.current[layerId]);
        delete updateTimeoutRef.current[layerId];
      }
    },
    [activeLayer]
  );

  const reorderLayers = useCallback((reorderedLayers) => {
    console.log('Reordering layers in LayerManager');
    setLayersState((prevLayers) => {
      const changedLayers = reorderedLayers.filter(
        (layer, index) => layer.id !== prevLayers[index]?.id
      );
      setSimpleUpdateQueue((current) => [
        ...current,
        ...changedLayers.map((layer) => 'reorder-' + layer.id),
      ]);
      return reorderedLayers;
    });
  }, []);

  const setEditingSize = useCallback((width, height) => {
    editingSizeRef.current = { width, height };
  }, []);

  const value = {
    layers,
    setLayers,
    layerPreviews,
    setLayerPreviews,
    geometryUpdateQueue,
    simpleUpdateQueue,
    clearGeometryUpdateQueue,
    clearSimpleUpdateQueue,
    visibleLayers: visibleLayersRef.current,
    activeLayer,
    setActiveLayer,
    addLayer,
    deleteLayer,
    setEditingSize,
    duplicateLayer,
    reorderLayers,
  };

  return (
    <LayerContext.Provider value={value}>{children}</LayerContext.Provider>
  );
};

// Helper function to omit specific properties from an object
const omit = (obj, keys) => {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result;
};
