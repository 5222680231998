import React, { useMemo, useCallback, forwardRef, useState } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import SegmentedEntityWrapper from './SegmentedEntityWrapper';
import { LiaFileExportSolid } from 'react-icons/lia';
import { FiRefreshCw } from 'react-icons/fi';

const SegmentSection = forwardRef(
  (
    {
      className,
      style,
      currentImageIndex,
      currentImage,
      images,
      setImages,
      draggedEntity,
      setDraggedEntity,
      isSegmenting,
      containerSize,
      onAutoLayers,
      processSegmentation,
      segmentationError,
      setSegmentationError,
    },
    ref
  ) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isReSegmenting, setIsReSegmenting] = useState(false);

    const [scaleX, scaleY] = useMemo(() => {
      if (
        currentImage &&
        currentImage.originalWidth &&
        currentImage.originalHeight
      ) {
        return [
          containerSize.width / currentImage.originalWidth,
          containerSize.height / currentImage.originalHeight,
        ];
      }
      return [1, 1];
    }, [currentImage, containerSize]);

    const handleDragStart = useCallback(
      (segment, e) => {
        console.log('Drag started:', segment.id);

        const dragScale = 0.8;

        setDraggedEntity({
          ...segment,
          svgUrl: segment.svgUrl,
          originalSection: 'segment',
          scale: dragScale,
          width: segment.width * scaleX,
          height: segment.height * scaleY,
          initialMouseX: e.evt.clientX,
          initialMouseY: e.evt.clientY,
        });
      },
      [scaleX, scaleY, setDraggedEntity]
    );

    const checkDragging = (segment) => {
      return draggedEntity && draggedEntity.id === segment.id;
    };

    const consistentStyle = {
      ...style,
      boxShadow: `
        inset 0 0 10px rgba(0,0,0,0.2),
        inset 0 0 20px rgba(0,0,0,0.1),
        inset 0 0 30px rgba(0,0,0,0.05)
      `,
    };

    const handleReSegmentClick = useCallback(() => {
      setIsReSegmenting(true);
      setSegmentationError(null); // Clear any previous errors

      setImages((prevImages) => {
        const newImages = [...prevImages];
        const currentImage = newImages[currentImageIndex];

        const clearedImage = {
          id: currentImage.id,
          raw: currentImage.raw,
          segments: [],
          restImage: null,
        };

        newImages[currentImageIndex] = clearedImage;
        setTimeout(() => {
          processSegmentation(clearedImage, true, false).finally(() => {
            setIsReSegmenting(false);
          });
        }, 0);

        return newImages;
      });
    }, [
      setImages,
      currentImageIndex,
      processSegmentation,
      setSegmentationError,
    ]);

    const handleAutoLayersClick = useCallback(() => {
      setIsProcessing(true);
      onAutoLayers();
      // Assume processing is done after 5 seconds (adjust as needed)
      setTimeout(() => setIsProcessing(false), 5000);
    }, [onAutoLayers]);

    const renderContent = () => {
      if (isSegmenting) {
        return (
          <div className="flex items-center">
            <div className="w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full animate-spin mr-2"></div>
            <span>Segmenting image...</span>
          </div>
        );
      }

      if (!currentImage || !currentImage.segments) {
        return <span>No image available</span>;
      }

      return (
        <Stage width={containerSize.width} height={containerSize.height}>
          <Layer>
            {currentImage.restImage && (
              <Image
                image={currentImage.restImage}
                width={containerSize.width}
                height={containerSize.height}
                opacity={0.5}
              />
            )}
            {currentImage.segments.map((segment) => (
              <SegmentedEntityWrapper
                images={images}
                key={`segment-${segment.id}`}
                entity={segment}
                scaleX={scaleX}
                scaleY={scaleY}
                onDragStart={handleDragStart}
                isInEditingSection={false}
                isDragging={checkDragging(segment)}
              />
            ))}
          </Layer>
        </Stage>
      );
    };

    return (
      <div
        className={`${className} flex items-center justify-center rounded-lg overflow-hidden relative`}
        style={consistentStyle}
        ref={ref}
      >
        {renderContent()}
        {currentImage && currentImage.segments && (
          <>
            {/* Re-Segment button */}
            <div className="absolute top-2 left-2">
              <div className="bg-white rounded-lg shadow-[0px_10px_30px_rgba(0,0,0,0.15)] p-2">
                <button
                  className={`text-blue-500 hover:text-blue-700 font-medium flex items-center justify-center w-full ${
                    isReSegmenting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleReSegmentClick}
                  disabled={isReSegmenting}
                >
                  {isReSegmenting ? (
                    <div className="w-5 h-5 border-2 border-blue-500 border-t-transparent rounded-full animate-spin mr-2"></div>
                  ) : (
                    <FiRefreshCw className="mr-1" size={20} />
                  )}
                  <span>{isReSegmenting ? 'Processing...' : 'Re-Segment'}</span>
                </button>
              </div>
              {segmentationError && (
                <div
                  className="mt-2 bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{segmentationError}</span>
                </div>
              )}
            </div>

            {/* Auto-Layers button */}
            <div className="absolute top-2 right-2">
              <div className="bg-white rounded-lg shadow-[0px_10px_30px_rgba(0,0,0,0.15)] p-2 flex flex-col items-center space-y-2">
                <button
                  className={`text-blue-500 hover:text-blue-700 font-medium flex items-center justify-center w-full ${
                    isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleAutoLayersClick}
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <div className="w-5 h-5 border-2 border-blue-500 border-t-transparent rounded-full animate-spin mr-2"></div>
                  ) : (
                    <LiaFileExportSolid className="mr-1" size={20} />
                  )}
                  <span>{isProcessing ? 'Processing...' : 'Auto-Layers'}</span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default React.memo(SegmentSection);
