import React, { useState, useEffect, useRef, useCallback } from 'react';
import SegmentSection from './components/SegmentSection';
import ImageSection from './components/ImageSection';
import EditingSection from './components/EditingSection';
import LayerSection from './components/LayerSection';
import FinalPreviewSection from './components/FinalPreviewSection';
import DraggedEntity from './components/DraggedEntity';
import { DragDropContext } from 'react-beautiful-dnd';
import { mockSegmentation } from './components/TestCode';
import { LayerManager, useLayerManager } from './components/LayerManager';

const API_BASE_URL = 'https://api.fabdreamer.art';

// Helper function to convert SVG to data URI
function svgToURI(svgString) {
  if (!svgString) {
    console.error('svgToDataURI received empty or undefined svgString');
    return null;
  }
  try {
    const encoded = encodeURIComponent(svgString);
    const dataURI = `data:image/svg+xml;base64,${btoa(unescape(encoded))}`;
    console.log('SVG Data URI created');
    return dataURI;
  } catch (error) {
    console.error('Error in svgToDataURI:', error);
    return null;
  }
}

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
    //console.log('Image loaded:', src);
  });
};
const Demo = () => {
  //for updating size in different section
  const [sizes, setSizes] = useState({ width: 0, height: 0 });
  //for loaded images
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSegmenting, setIsSegmenting] = useState(false);
  // for entity management
  const [draggedEntity, setDraggedEntity] = useState(null);
  const [segmentationError, setSegmentationError] = useState(null);

  const appRef = useRef(null);
  const editingSectionRef = useRef(null);
  const segmentSectionRef = useRef(null);
  const imagesRef = useRef(images);
  const currentImageIndexRef = useRef(currentImageIndex);

  useEffect(() => {
    imagesRef.current = images;
    currentImageIndexRef.current = currentImageIndex;
  }, [images, currentImageIndex]);

  // Use the LayerManager context
  const {
    layers,
    setLayers,
    layerPreviews,
    visibleLayers,
    activeLayer,
    setActiveLayer,
    addLayer,
    deleteLayer,
    setEditingSize,
    reorderLayers,
  } = useLayerManager();

  //update app size
  useEffect(() => {
    const updateSizes = () => {
      if (appRef.current) {
        const vh = window.innerHeight;
        const vw = window.innerWidth;
        const headerHeight = 48;
        const availableHeight = vh - headerHeight - 32;
        const availableWidth = Math.min(vw - 32, 2400);

        const squareSize = availableHeight / 2;
        const columnWidth = squareSize;
        const editingWidth = squareSize * 2;
        const rightColumnWidth =
          availableWidth - columnWidth - editingWidth - 32;

        setSizes({
          square: squareSize,
          editingWidth: editingWidth,
          leftColumnWidth: columnWidth,
          rightColumnWidth: rightColumnWidth,
          totalHeight: availableHeight,
          contentWidth: availableWidth,
        });
      }
    };

    window.addEventListener('resize', updateSizes);
    updateSizes();
    return () => window.removeEventListener('resize', updateSizes);
  }, []);

  useEffect(() => {
    setEditingSize(sizes.editingWidth, sizes.totalHeight);
  }, [sizes.editingWidth, sizes.totalHeight, setEditingSize]);

  const updateEntities = useCallback(
    (updatedData, index = currentImageIndex) => {
      setImages((prevImages) => {
        const newImages = [...prevImages];
        const currentImage = { ...newImages[index], ...updatedData };

        if (updatedData.layers) {
          currentImage.segments = updatedData.layers.flatMap((layer) =>
            layer.segments.map((segmentData) => ({
              id: segmentData.id,
              segmentId: segmentData.segmentId,
              svgUrl: segmentData.svgUrl,
              x: segmentData.boundingBox.x,
              y: segmentData.boundingBox.y,
              width: segmentData.boundingBox.width,
              height: segmentData.boundingBox.height,
              originalX: segmentData.boundingBox.x,
              originalY: segmentData.boundingBox.y,
              originalWidth: segmentData.boundingBox.width,
              originalHeight: segmentData.boundingBox.height,
              rotation: 0,
              originalLayerIndex: layer.layerId,
              currentStyle: 'original',
              styles: {
                original: segmentData.svgUrl, // Initialize the "original" style
              },
            }))
          );
        }

        if (updatedData.restImage) {
          currentImage.restImage = updatedData.restImage;
        }

        if (updatedData.originalWidth && updatedData.originalHeight) {
          currentImage.originalWidth = updatedData.originalWidth;
          currentImage.originalHeight = updatedData.originalHeight;
        }

        newImages[index] = currentImage;
        return newImages;
      });
    },
    [currentImageIndex]
  );

  const handleImageChange = useCallback((index) => {
    setCurrentImageIndex(index);
  }, []);

  const handleNewImageLoad = useCallback((newImageData) => {
    setImages((prevImages) => [...prevImages, newImageData]);
    setCurrentImageIndex((prevImages) => prevImages.length);
  }, []);

  const handlePreparedNewImage = useCallback(
    async (imageIndex, segmentationResult, needNewLayer) => {
      console.log('Starting PreparedNewImage');

      try {
        // Convert SVG data to URLs
        const preparedLayers = segmentationResult.layers.map((layer) => ({
          ...layer,
          segments: layer.segments.map((segment) => ({
            ...segment,
            id: `segment-${imageIndex}-${layer.layerId}-${segment.segmentId}`,
            svgUrl: svgToURI(segment.svgData),
          })),
        }));
        console.log('Prepared layers:', preparedLayers);

        // Load the restImage
        let loadedRestImage = null;
        if (segmentationResult.restImage) {
          loadedRestImage = await loadImage(segmentationResult.restImage);
        }

        // Update the entities with SVG URLs and loaded restImage
        updateEntities(
          {
            layers: preparedLayers,
            restImage: loadedRestImage,
            originalWidth: segmentationResult.originalWidth,
            originalHeight: segmentationResult.originalHeight,
          },
          imageIndex
        );

        if (needNewLayer) {
          // Create a new empty layer for the prepared segments
          const newLayer = {
            id: Date.now(),
            name: `Layer ${layers.length + 1}`,
            segments: [],
            isVisible: true,
            frameStrokeWidth: 50,
            isFrameActive: true,
            frameShape: 'squareOutline',
          };
          addLayer(newLayer);
        }

        console.log('Image preparation complete');
      } catch (error) {
        console.error('Failed to prepare image:', error);
      } finally {
        setIsSegmenting(false);
      }
    },
    [layers, updateEntities, addLayer]
  );

  const processSegmentation = useCallback(
    //TODO: in dev mode, change to useRealAPI = false
    async (imageData, useRealAPI = false, needNewLayer = true) => {
      try {
        setIsSegmenting(true);
        setSegmentationError(null); // Clear any previous errors
        let segmentationData;

        if (useRealAPI) {
          const base64Image = imageData.raw;
          const response = await fetch(`${API_BASE_URL}/detect-and-segment`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ base64_image: base64Image }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          //console.log(data);

          segmentationData = {
            layers: data.layers
              .map((layer, index, array) => ({
                layerId: array.length - 1 - index,
                segments: layer.segments
                  .map((segment) => ({
                    segmentId: segment.segmentId,
                    svgData: segment.svgData,
                    boundingBox: {
                      x: segment.boundingBox.x,
                      y: segment.boundingBox.y,
                      width: segment.boundingBox.width,
                      height: segment.boundingBox.height,
                    },
                  }))
                  .reverse(),
              }))
              .reverse(),
            restImage: data.restImage,
            originalWidth: 1024,
            originalHeight: 1024,
          };
        } else {
          segmentationData = await mockSegmentation(imageData.raw);
        }

        handlePreparedNewImage(imageData.id, segmentationData, needNewLayer);
      } catch (segmentationError) {
        console.error('Error during segmentation:', segmentationError);
        setSegmentationError('Backend Error'); // Set the error message
      } finally {
        setIsSegmenting(false);
      }
    },
    [handlePreparedNewImage, setIsSegmenting]
  );

  const handleDraggedEntityDragEnd = useCallback(
    async (segment, pos) => {
      //console.log(editingSectionRef.current);
      const editingSectionRect =
        editingSectionRef.current.getBoundingClientRect();

      const isInEditingSection =
        pos.x >= editingSectionRect.left &&
        pos.x <= editingSectionRect.right &&
        pos.y >= editingSectionRect.top &&
        pos.y <= editingSectionRect.bottom;

      if (isInEditingSection) {
        const originalImage = images[currentImageIndex];

        const scaleX = sizes.editingWidth / originalImage.originalWidth;
        const scaleY = sizes.totalHeight / originalImage.originalHeight;

        const newSegment = {
          ...segment,
          id: `${segment.id}-edit-${Date.now()}`,
          x: segment.originalX * scaleX,
          y: segment.originalY * scaleY,
          width: segment.originalWidth,
          height: segment.originalHeight,
          scaleX: scaleX,
          scaleY: scaleY,
          rotation: 0,
          styledSvg: segment.styles['original'],
          styles: {},
        };

        setLayers((prevLayers) => {
          const updatedLayers = prevLayers.map((layer) =>
            layer.id === activeLayer
              ? {
                  ...layer,
                  segments: [...layer.segments, newSegment],
                  frameStrokeWidth: layer.frameStrokeWidth || 50,
                  isFrameActive:
                    layer.isFrameActive !== undefined
                      ? layer.isFrameActive
                      : true,
                  frameShape: layer.frameShape || 'squareOutline',
                }
              : layer
          );

          return updatedLayers;
        });
      }
      setDraggedEntity(null);
    },
    [images, currentImageIndex, sizes, activeLayer, setLayers]
  );

  const handleAutoLayers = useCallback(async () => {
    console.log('Starting handleAutoLayers');
    const currentImage = images[currentImageIndex];
    if (!currentImage || !currentImage.segments) {
      console.log('No current image or segments found');
      return;
    }

    const segmentGroups = currentImage.segments.reduce((groups, segment) => {
      const index = segment.originalLayerIndex;
      if (!groups[index]) {
        groups[index] = [];
      }
      groups[index].push(segment);
      return groups;
    }, {});

    console.log('Segment groups:', segmentGroups);

    const scaleX = sizes.editingWidth / currentImage.originalWidth;
    const scaleY = sizes.totalHeight / currentImage.originalHeight;

    const createLayer = (layerIndex, segments) => {
      return new Promise((resolve) => {
        console.log(
          `Start processing layer ${layerIndex} with ${segments.length} segments`
        );

        const updatedSegments = segments.map((segment) => ({
          ...segment,
          id: `${segment.id}-edit-${Date.now()}`,
          x: segment.originalX * scaleX,
          y: segment.originalY * scaleY,
          width: segment.originalWidth,
          height: segment.originalHeight,
          scaleX: scaleX,
          scaleY: scaleY,
          rotation: 0,
          styledSvg: segment.styles['original'],
          styles: {},
        }));

        const newLayer = {
          id: `auto-layer-${Date.now()}-${layerIndex}`,
          name: `Auto Layer ${layerIndex}`,
          isVisible: true,
          segments: updatedSegments,
          frameStrokeWidth: 50,
          isFrameActive: true,
          frameShape: 'squareOutline',
        };

        console.log(`Adding new layer: ${newLayer.id}`);

        addLayer(newLayer, () => {
          console.log(`Layer preview ready for ${newLayer.id}`);
          resolve();
        });

        setActiveLayer(newLayer.id);
        console.log(`Layer added and set active: ${newLayer.id}`);
      });
    };

    console.log(`Processing ${Object.entries(segmentGroups).length} layers`);
    for (const [layerIndex, segments] of Object.entries(segmentGroups)) {
      await createLayer(layerIndex, segments);
      console.log(`Finished processing layer ${layerIndex}`);
    }

    console.log('Finished handleAutoLayers');
  }, [images, currentImageIndex, sizes, addLayer, setActiveLayer]);

  const onLayerSetDragEnd = (result) => {
    if (!result.destination) return;
    console.log('reordering layers');
    const items = Array.from(layers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    reorderLayers(items);
  };

  return (
    <div
      ref={appRef}
      className="flex flex-col min-h-screen bg-white"
      style={{ marginTop: '0px' }}
    >
      <div className="flex-1 flex items-center justify-center p-4 pt-16">
        <div
          className="flex space-x-4 items-stretch justify-center"
          style={{ height: sizes.totalHeight, width: sizes.contentWidth }}
        >
          <div
            className="flex flex-col space-y-4"
            style={{ width: sizes.leftColumnWidth }}
          >
            <SegmentSection
              className="bg-white"
              style={{ width: sizes.square, height: sizes.square }}
              ref={segmentSectionRef}
              isSegmenting={isSegmenting}
              images={images}
              setImages={setImages}
              currentImageIndex={currentImageIndex}
              currentImage={images[currentImageIndex]}
              draggedEntity={draggedEntity}
              setDraggedEntity={setDraggedEntity}
              containerSize={{ width: sizes.square, height: sizes.square }}
              onAutoLayers={handleAutoLayers}
              processSegmentation={processSegmentation}
              segmentationError={segmentationError}
              setSegmentationError={setSegmentationError}
            />
            <ImageSection
              className="bg-gray-300"
              style={{ width: sizes.square, height: sizes.square }}
              images={images}
              currentImageIndex={currentImageIndex}
              setCurrentImageIndex={setCurrentImageIndex}
              onImageChange={handleImageChange}
              onNewImageLoad={handleNewImageLoad}
              processSegmentation={processSegmentation}
            />
          </div>

          <EditingSection
            className="bg-gray-300 editing-section"
            style={{ width: sizes.editingWidth, height: sizes.totalHeight }}
            ref={editingSectionRef}
            activeLayer={activeLayer}
            images={images}
            setImages={setImages}
            layers={layers}
            setLayers={setLayers}
            editingSectionSize={{
              width: sizes.editingWidth,
              height: sizes.totalHeight,
            }}
          />

          <div
            className="flex flex-col space-y-4"
            style={{ width: sizes.rightColumnWidth, height: sizes.totalHeight }}
          >
            <div className="flex-grow flex flex-col overflow-visible">
              <div
                className="flex-shrink bg-white rounded-lg shadow-[0px_10px_30px_rgba(0,0,0,0.15)] mb-4 relative"
                style={{
                  maxHeight: sizes.totalHeight - sizes.rightColumnWidth - 56, // 16px for mb-4
                }}
              >
                <DragDropContext onDragEnd={onLayerSetDragEnd}>
                  <div className="max-h-full p-4 overflow-y-auto">
                    <LayerSection
                      style={{ width: sizes.rightColumnWidth - 32 }} // 32px for p-4 padding
                      images={images}
                      layers={layers}
                      setLayers={setLayers}
                      setActiveLayer={setActiveLayer}
                      activeLayer={activeLayer}
                      showAddButton={images.length > 0}
                      layerPreviews={layerPreviews}
                      deleteLayer={deleteLayer}
                      addLayer={addLayer}
                    />
                  </div>
                </DragDropContext>
              </div>
            </div>

            <div
              className="flex-shrink-0"
              style={{
                height: sizes.rightColumnWidth,
                position: 'relative',
                zIndex: 30,
              }}
            >
              <FinalPreviewSection
                className="bg-white rounded-lg overflow-hidden relative"
                rightColumnWidth={sizes.rightColumnWidth}
                totalHeight={sizes.totalHeight}
                visibleLayers={visibleLayers}
                layerPreviews={layerPreviews}
                activeLayerId={activeLayer ? activeLayer.id : null}
              />
            </div>
          </div>
        </div>
      </div>

      {draggedEntity && (
        <DraggedEntity
          entity={draggedEntity}
          onDragEnd={handleDraggedEntityDragEnd}
        />
      )}
    </div>
  );
};

const WrappedDemo = () => (
  <LayerManager>
    <Demo />
  </LayerManager>
);

export default WrappedDemo;
