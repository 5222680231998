import React, { useEffect, useState, useRef } from 'react';

const DraggedEntity = ({ entity, onDragEnd }) => {
  const [position, setPosition] = useState({
    x: entity.initialMouseX,
    y: entity.initialMouseY,
  });
  const [dimensions, setDimensions] = useState({
    width: entity.width * entity.scale,
    height: entity.height * entity.scale,
  });
  const svgRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({
        x: e.clientX - dimensions.width / 2,
        y: e.clientY - dimensions.height / 2,
      });
    };

    const handleMouseUp = (e) => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      onDragEnd(entity, { x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [entity, onDragEnd, dimensions.width, dimensions.height]);

  const backgroundExpansion = 10;

  return (
    <div
      style={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        zIndex: 1000,
        cursor: 'grabbing',
        pointerEvents: 'none',
      }}
    >
      <svg
        ref={svgRef}
        width={dimensions.width + backgroundExpansion * 2}
        height={dimensions.height + backgroundExpansion * 2}
        viewBox={`0 0 ${dimensions.width + backgroundExpansion * 2} ${
          dimensions.height + backgroundExpansion * 2
        }`}
        style={{ filter: 'drop-shadow(0px 5px 5px rgba(0,0,0,0.3))' }}
      >
        <defs>
          <filter id="whiteFill">
            <feFlood floodColor="white" result="white" />
            <feComposite in="white" in2="SourceAlpha" operator="in" />
          </filter>
          <filter id="expandMask">
            <feMorphology operator="dilate" radius={backgroundExpansion / 2} />
          </filter>
        </defs>
        <mask id="expandedMask">
          <image
            href={entity.svgUrl}
            x={backgroundExpansion}
            y={backgroundExpansion}
            width={dimensions.width}
            height={dimensions.height}
            filter="url(#expandMask)"
          />
        </mask>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="white"
          mask="url(#expandedMask)"
        />
        <image
          href={entity.svgUrl}
          x={backgroundExpansion}
          y={backgroundExpansion}
          width={dimensions.width}
          height={dimensions.height}
        />
      </svg>
    </div>
  );
};

export default DraggedEntity;
