import React, { useMemo } from 'react';
import ThreeDView from './ThreeDView';

const FinalPreview = React.memo(
  ({ visibleLayers, layerPreviews, woodTextureUrl, activeLayerId }) => {
    //const threeDViewRef = useRef(null);

    const memoizedThreeDView = useMemo(
      () => (
        <ThreeDView
          layers={visibleLayers}
          layerPreviews={layerPreviews}
          woodTextureUrl={woodTextureUrl}
          activeLayerId={activeLayerId}
        />
      ),
      [visibleLayers, layerPreviews, woodTextureUrl, activeLayerId]
    );

    return memoizedThreeDView;
  }
);

export default FinalPreview;
