import Konva from 'konva';

export const renderLayerToPng = (layer, width, height) => {
  return new Promise((resolve, reject) => {
    try {
      const stage = new Konva.Stage({
        container: document.createElement('div'),
        width: width,
        height: height,
      });

      const contentLayer = new Konva.Layer();
      const frameLayer = new Konva.Layer();
      stage.add(contentLayer, frameLayer);

      let loadedImages = 0;
      const totalImages = layer.segments.length;

      const renderFrame = () => {
        if (layer.isFrameActive) {
          drawFrame(frameLayer, layer, width, height);
        }

        stage.draw();
        const dataUrl = stage.toDataURL();
        stage.destroy();
        resolve(dataUrl);
      };

      if (totalImages === 0) {
        renderFrame();
        return;
      }

      layer.segments.forEach((segment) => {
        const imageObj = new Image();
        imageObj.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues
        imageObj.src = segment.styledSvg;

        imageObj.onload = function () {
          const konvaImage = new Konva.Image({
            x: segment.x,
            y: segment.y,
            image: imageObj,
            width: segment.originalWidth,
            height: segment.originalHeight,
            scaleX: segment.scaleX || 1,
            scaleY: segment.scaleY || 1,
            rotation: segment.rotation || 0,
          });
          contentLayer.add(konvaImage);

          loadedImages++;

          if (loadedImages === totalImages) {
            contentLayer.batchDraw();
            renderFrame();
          }
        };

        imageObj.onerror = function (error) {
          console.error('Error loading image:', error);
          reject(error);
        };
      });
    } catch (error) {
      console.error('Error rendering layer to PNG:', error);
      reject(error);
    }
  });
};

function drawFrame(frameLayer, layer, width, height) {
  const { frameShape, frameStrokeWidth } = layer;
  const MIN_STROKE_WIDTH = 50;
  const strokeWidth = Math.max(
    frameStrokeWidth || MIN_STROKE_WIDTH,
    MIN_STROKE_WIDTH
  );

  const outerShape = new Konva.Shape({
    sceneFunc: (context, shape) => {
      context.beginPath();
      if (frameShape.includes('square')) {
        context.rect(0, 0, width, height);
      } else {
        context.ellipse(
          width / 2,
          height / 2,
          width / 2,
          height / 2,
          0,
          0,
          2 * Math.PI
        );
      }
      context.fillStrokeShape(shape);
    },
    fill: 'rgb(156, 163, 175)',
  });

  const innerShape = new Konva.Shape({
    sceneFunc: (context, shape) => {
      context.beginPath();
      if (frameShape.includes('square')) {
        context.rect(
          strokeWidth,
          strokeWidth,
          width - 2 * strokeWidth,
          height - 2 * strokeWidth
        );
      } else {
        const radiusX = (width - 2 * strokeWidth) / 2;
        const radiusY = (height - 2 * strokeWidth) / 2;
        context.ellipse(
          width / 2,
          height / 2,
          radiusX,
          radiusY,
          0,
          0,
          2 * Math.PI
        );
      }
      context.fillStrokeShape(shape);
    },
    fill: 'white',
    globalCompositeOperation: 'destination-out',
  });

  frameLayer.add(outerShape);
  frameLayer.add(innerShape);
}
