import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useFrame } from '@react-three/fiber';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import * as THREE from 'three';

const LAYER_DEPTH = 0.3;
const LAYER_GAP = 0.3;

const CutBoard = forwardRef(
  (
    {
      layerId,
      layerPreview,
      position,
      woodTexture,
      layerIndex,
      totalLayers,
      isVisible,
      needsUpdate,
      isNew,
      onProcessed,
    },
    ref
  ) => {
    const [geometries, setGeometries] = useState({});
    const [center, setCenter] = useState(new THREE.Vector3());
    const groupRef = useRef();
    const [targetY, setTargetY] = useState(0);

    console.log(`CutBoard ${layerId} rendering. isVisible:`, isVisible);

    const material = useMemo(
      () =>
        new THREE.MeshPhysicalMaterial({
          map: woodTexture,
          side: THREE.DoubleSide,
          roughness: 0.9,
          metalness: 0.0,
          clearcoat: 0,
          clearcoatRoughness: 0,
          reflectivity: 0.1,
          envMapIntensity: 0.2,
        }),
      [woodTexture]
    );

    const processGeometries = useCallback(() => {
      if (!layerPreview || !layerPreview.svg) {
        return null;
      }

      console.log('Processing SVG for layer', layerId);

      const loader = new SVGLoader();
      const svgData = loader.parse(layerPreview.svg);

      const newGeometries = {};
      const boundingBox = new THREE.Box3();

      svgData.paths.forEach((path, index) => {
        const { fill, fillOpacity, stroke, strokeOpacity } =
          path.userData.style;
        if (fill === 'rgb(0,0,0)' || stroke === 'rgb(0,0,0)') return;
        if ((fill && fillOpacity > 0) || (stroke && strokeOpacity > 0)) {
          const shapes = SVGLoader.createShapes(path);

          const key = `path_${index}`;
          const geometry = new THREE.ExtrudeGeometry(shapes, {
            depth: LAYER_DEPTH * 100,
            bevelEnabled: true,
            bevelThickness: 0.001,
            bevelSize: 0.001,
            bevelSegments: 1,
          });
          newGeometries[key] = { geometry, shapes };
          geometry.computeBoundingBox();
          boundingBox.union(geometry.boundingBox);
        }
      });

      const newCenter = new THREE.Vector3();
      boundingBox.getCenter(newCenter);

      return { geometries: newGeometries, center: newCenter };
    }, [layerPreview, layerId]);

    const updateGeometry = useCallback(() => {
      console.log('Updating geometry for layer', layerId);
      const result = processGeometries();
      if (result) {
        setGeometries(result.geometries);
        setCenter(result.center);
      }
      onProcessed();
    }, [layerId, processGeometries, onProcessed]);

    useImperativeHandle(ref, () => ({
      //updateGeometry,
      updatePosition: () => {
        console.log('Updating position for layer', layerId);
        updateTargetY();
      },
    }));

    const updateTargetY = useCallback(() => {
      const newTargetY =
        (totalLayers - 1 - layerIndex) * (LAYER_DEPTH + LAYER_GAP);
      console.log(
        `CutBoard ${layerId}: Setting targetY to ${newTargetY}. layerIndex:`,
        layerIndex,
        'totalLayers:',
        totalLayers
      );
      setTargetY(newTargetY);
    }, [totalLayers, layerIndex, layerId]);

    useEffect(() => {
      updateTargetY();
    }, [updateTargetY, layerIndex, totalLayers]);

    useEffect(() => {
      if (isNew || needsUpdate || Object.keys(geometries).length === 0) {
        console.log(
          `CutBoard: ${isNew ? 'Creating' : 'Updating'} layer`,
          layerId
        );
        updateGeometry();
      }
    }, [isNew, needsUpdate, layerId, updateGeometry, geometries]);

    useFrame(() => {
      if (groupRef.current) {
        groupRef.current.position.y +=
          (targetY - groupRef.current.position.y) * 0.1;
      }
    });

    if (Object.keys(geometries).length === 0) return null;

    return (
      <group
        ref={groupRef}
        position={[position[0], 0, position[2]]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[0.01, -0.01, 0.01]}
      >
        {Object.entries(geometries).map(([key, { geometry }]) => (
          <mesh
            key={key}
            geometry={geometry}
            material={material}
            position={[-center.x, -center.y, 0]}
            castShadow
            receiveShadow
          />
        ))}
      </group>
    );
  }
);

export default CutBoard;
