import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useMemo,
  useImperativeHandle,
  lazy,
  Suspense,
} from 'react';
import { Stage, Layer, Transformer } from 'react-konva';
import SegmentedEntityWrapper from './SegmentedEntityWrapper';
import OutFrame from './EditingSection/OutFrame';
import Konva from 'konva';
import { updateLayerState } from './EditingSection/layerUtils';

const SegmentEditingSection = lazy(() =>
  import('./EditingSection/SegmentEditingSection')
);
const LayerToolBar = lazy(() => import('./EditingSection/LayerToolBar'));

const MIN_STROKE_WIDTH = 50;

const EditingSection = forwardRef(
  (
    {
      className,
      style,
      activeLayer,
      images,
      setImages,
      layers,
      setLayers,
      editingSectionSize,
    },
    ref
  ) => {
    const [selectedSegments, setSelectedSegments] = useState([]);
    const [operations, setOperations] = useState([]);
    const [currentOperationIndex, setCurrentOperationIndex] = useState(-1);
    const [isFrameSelected, setIsFrameSelected] = useState(false);
    const [scaleIndicator, setScaleIndicator] = useState('3%');
    const [isLoading, setIsLoading] = useState(false);
    const [isShiftPressed, setIsShiftPressed] = useState(false);

    const [dragStartPositions, setDragStartPositions] = useState({});
    const [transformStartState, setTransformStartState] = useState(null);

    const canUndo = currentOperationIndex > -1;
    const canRedo = currentOperationIndex < operations.length - 1;

    const containerRef = useRef(null);
    const stageRef = useRef(null);
    const transformerRef = useRef(null);
    const toolbarRef = useRef(null);
    const frameRef = useRef(null);

    useImperativeHandle(ref, () => ({
      getStageRef: () => stageRef.current,
      getBoundingClientRect: () => containerRef.current.getBoundingClientRect(),
    }));

    const memoizedLayers = useMemo(() => layers, [layers]);
    const activeLayerData = useMemo(
      () => memoizedLayers.find((layer) => layer.id === activeLayer),
      [memoizedLayers, activeLayer]
    );
    const { isFrameActive, frameShape } = activeLayerData || {};

    const addOperation = useCallback(
      (operation) => {
        setOperations((prevOperations) => [
          ...prevOperations.slice(0, currentOperationIndex + 1),
          operation,
        ]);
        setCurrentOperationIndex((prevIndex) => prevIndex + 1);
      },
      [currentOperationIndex]
    );

    const handleUndo = useCallback(() => {
      if (canUndo) {
        const prevOperation = operations[currentOperationIndex];
        prevOperation.undo();
        setCurrentOperationIndex((prevIndex) => prevIndex - 1);
      }
    }, [canUndo, operations, currentOperationIndex]);

    const handleRedo = useCallback(() => {
      if (canRedo) {
        const nextOperation = operations[currentOperationIndex + 1];
        nextOperation.redo();
        setCurrentOperationIndex((prevIndex) => prevIndex + 1);
      }
    }, [canRedo, operations, currentOperationIndex]);

    const handleSegmentSelect = useCallback(
      (segment) => {
        console.log('Shift key pressed:', isShiftPressed);

        setSelectedSegments((prevSelectedSegments) => {
          if (isShiftPressed) {
            const isAlreadySelected = prevSelectedSegments.some(
              (s) => s.id === segment.id
            );
            if (isAlreadySelected) {
              return prevSelectedSegments.filter((s) => s.id !== segment.id);
            } else {
              return [...prevSelectedSegments, segment];
            }
          } else {
            return [segment];
          }
        });

        setIsFrameSelected(false);
      },
      [isShiftPressed]
    );

    const handleDragStart = useCallback(
      (segment) => {
        console.log('Drag Start:', segment);
        setDragStartPositions((prev) => ({
          ...prev,
          [segment.id]: { x: segment.x, y: segment.y },
        }));
        if (
          !isShiftPressed &&
          !selectedSegments.some((s) => s.id === segment.id)
        ) {
          setSelectedSegments([segment]);
        }
      },
      [isShiftPressed, selectedSegments]
    );

    const handleDragMove = useCallback(
      (e, newPos, draggedEntity) => {
        //console.log('Drag Move - New Position:', newPos);
        const startPos = dragStartPositions[draggedEntity.id];
        if (!startPos) {
          console.warn(
            'Drag Move called without start position for',
            draggedEntity.id
          );
          return;
        }

        const dx = newPos.x - startPos.x;
        const dy = newPos.y - startPos.y;
        //console.log('Calculated Delta:', { dx, dy });

        setSelectedSegments((prev) => {
          const updated = prev.map((segment) => ({
            ...segment,
            x: dragStartPositions[segment.id].x + dx,
            y: dragStartPositions[segment.id].y + dy,
          }));
          //console.log('Updated Segments during Move:', updated);
          return updated;
        });
      },
      [dragStartPositions]
    );

    const handleDragEnd = useCallback(
      (e, newPos, draggedEntity) => {
        console.log('Drag End - Final Position:', newPos);
        const startPos = dragStartPositions[draggedEntity.id];
        if (!startPos) {
          console.warn(
            'Drag End called without start position for',
            draggedEntity.id
          );
          return;
        }

        const isMultipleSelection = selectedSegments.length > 1;
        const isLastInGroup =
          isMultipleSelection &&
          draggedEntity.id === selectedSegments[selectedSegments.length - 1].id;

        const dx = newPos.x - startPos.x;
        const dy = newPos.y - startPos.y;
        console.log('Final Delta:', { dx, dy });

        const updatedSegments = selectedSegments.map((segment) => ({
          ...segment,
          x: dragStartPositions[segment.id].x + dx,
          y: dragStartPositions[segment.id].y + dy,
        }));
        //console.log('Updated Segments after Drag:', updatedSegments);

        // Update selected segments state
        setSelectedSegments(updatedSegments);

        // Only update layers if this is a single selection or the last item in a group
        if (!isMultipleSelection || isLastInGroup) {
          setLayers((prevLayers) => {
            const updatedLayers = prevLayers.map((layer) => ({
              ...layer,
              segments: layer.segments.map((segment) => {
                const updatedSegment = updatedSegments.find(
                  (s) => s.id === segment.id
                );
                return updatedSegment || segment;
              }),
            }));

            console.log('Updated Layers:', updatedLayers);

            const operation = {
              undo: () => setLayers(prevLayers),
              redo: () => setLayers(updatedLayers),
            };
            addOperation(operation);

            return updatedLayers;
          });

          console.log('Layers updated');
        } else {
          console.log('Skipping layer update - not last in group');
        }

        // Clear drag start positions if it's the last in group or single selection
        if (!isMultipleSelection || isLastInGroup) {
          setDragStartPositions({});
        }

        // Log updates
        updatedSegments.forEach((segment) => {
          console.log(`Updated segment ${segment.id}:`, segment);
        });
      },
      [selectedSegments, addOperation, setLayers, dragStartPositions]
    );

    const handleTransformEnd = useCallback(
      (e, newProps, transformedEntity) => {
        console.log('Transform end triggered');
        console.log('New props:', newProps);
        console.log('Transformed entity:', transformedEntity);

        const isMultipleSelection = selectedSegments.length > 1;
        const isLastInGroup =
          isMultipleSelection &&
          transformedEntity.id ===
            selectedSegments[selectedSegments.length - 1].id;

        // Calculate the scale change and position change
        const scaleChangeX = newProps.scaleX / (transformedEntity.scaleX || 1);
        const scaleChangeY = newProps.scaleY / (transformedEntity.scaleY || 1);
        const rotationChange =
          newProps.rotation - (transformedEntity.rotation || 0);
        const positionChangeX = newProps.x - transformedEntity.x;
        const positionChangeY = newProps.y - transformedEntity.y;

        console.log('Changes:', {
          scaleChangeX,
          scaleChangeY,
          rotationChange,
          positionChangeX,
          positionChangeY,
        });

        // Accumulate changes for all selected segments
        const updatedSelectedSegments = selectedSegments.map((segment) => {
          if (segment.id === transformedEntity.id) {
            return {
              ...segment,
              ...newProps,
            };
          } else if (isMultipleSelection) {
            // Calculate new position relative to the transformed entity
            const dx = segment.x - transformedEntity.x;
            const dy = segment.y - transformedEntity.y;
            const angle = (rotationChange * Math.PI) / 180;
            const newX =
              newProps.x +
              (dx * Math.cos(angle) - dy * Math.sin(angle)) * scaleChangeX;
            const newY =
              newProps.y +
              (dx * Math.sin(angle) + dy * Math.cos(angle)) * scaleChangeY;

            return {
              ...segment,
              x: newX,
              y: newY,
              scaleX: segment.scaleX * scaleChangeX,
              scaleY: segment.scaleY * scaleChangeY,
              rotation: segment.rotation + rotationChange,
            };
          }
          return segment;
        });

        // Update selected segments state
        setSelectedSegments(updatedSelectedSegments);

        // Only update layers if this is a single selection or the last item in a group
        if (!isMultipleSelection || isLastInGroup) {
          setLayers((prevLayers) => {
            const updatedLayers = prevLayers.map((layer) => ({
              ...layer,
              segments: layer.segments.map((segment) => {
                const updatedSegment = updatedSelectedSegments.find(
                  (s) => s.id === segment.id
                );
                return updatedSegment || segment;
              }),
            }));

            const operation = {
              undo: () => setLayers(prevLayers),
              redo: () => setLayers(updatedLayers),
            };
            addOperation(operation);

            return updatedLayers;
          });

          console.log('Layers updated');
        } else {
          console.log('Skipping layer update - not last in group');
        }

        // Log updates
        updatedSelectedSegments.forEach((segment) => {
          console.log(`Updated segment ${segment.id}:`, segment);
        });
      },
      [selectedSegments, addOperation, setLayers]
    );

    const handleFrameTransform = useCallback(
      (e) => {
        const { strokeWidth: newStrokeWidth } = e.target;
        const finalStrokeWidth = Math.max(MIN_STROKE_WIDTH, newStrokeWidth);
        setLayers((prevLayers) =>
          prevLayers.map((layer) =>
            layer.id === activeLayer
              ? { ...layer, frameStrokeWidth: finalStrokeWidth }
              : layer
          )
        );
      },
      [activeLayer, setLayers]
    );

    const handleFrameToggle = useCallback(
      (isActive) => {
        setLayers((prevLayers) =>
          prevLayers.map((layer) =>
            layer.id === activeLayer
              ? { ...layer, isFrameActive: isActive }
              : layer
          )
        );
      },
      [activeLayer, setLayers]
    );

    const handleShapeChange = useCallback(
      (shape) => {
        setLayers((prevLayers) =>
          prevLayers.map((layer) =>
            layer.id === activeLayer ? { ...layer, frameShape: shape } : layer
          )
        );
      },
      [activeLayer, setLayers]
    );

    const handleFrameSelect = useCallback(() => {
      setIsFrameSelected(true);
      setSelectedSegments([]);
    }, []);

    const handleGlobalClick = useCallback(
      (e) => {
        if (containerRef.current && selectedSegments.length >= 1) {
          const isClickInsideEditingSection = containerRef.current.contains(
            e.target
          );

          if (!isClickInsideEditingSection) {
            setSelectedSegments([]);
            console.log('Deselected all segments due to outside click');
          }
        }
      },
      [selectedSegments.length, setSelectedSegments]
    );

    useEffect(() => {
      document.addEventListener('mousedown', handleGlobalClick);
      return () => {
        document.removeEventListener('mousedown', handleGlobalClick);
      };
    }, [handleGlobalClick]);

    useEffect(() => {
      const handleKeyDown = (e) => {
        if (e.key === 'Shift') setIsShiftPressed(true);
      };

      const handleKeyUp = (e) => {
        if (e.key === 'Shift') setIsShiftPressed(false);
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }, []);

    useEffect(() => {
      if (transformerRef.current) {
        const nodes = selectedSegments
          .map((segment) => stageRef.current.findOne(`#${segment.id}`))
          .filter(Boolean);
        transformerRef.current.nodes(nodes);
        transformerRef.current.getLayer().batchDraw();
      }
    }, [selectedSegments]);

    // Update the useEffect for the transformer

    const frameProps = useMemo(() => {
      if (!activeLayerData) return null;

      return {
        isFrameActive: activeLayerData.isFrameActive ?? true,
        currentShape: activeLayerData.frameShape ?? 'squareOutline',
        strokeWidth: activeLayerData.frameStrokeWidth ?? MIN_STROKE_WIDTH,
        editingSectionSize: editingSectionSize,
        onTransformEnd: handleFrameTransform,
        onSelect: handleFrameSelect,
        isSelected: isFrameSelected,
        onScaleChange: setScaleIndicator,
      };
    }, [
      activeLayerData,
      editingSectionSize,
      handleFrameTransform,
      handleFrameSelect,
      isFrameSelected,
    ]);

    const memoizedSegments = useMemo(() => {
      const activeLayerData = layers.find((layer) => layer.id === activeLayer);
      return activeLayerData
        ? activeLayerData.segments.map((segment) => (
            <SegmentedEntityWrapper
              key={segment.id}
              entity={{
                ...segment,
                id: segment.id,
                currentStyle: segment.currentStyle || 'original',
              }}
              images={images}
              scaleX={segment.scaleX}
              scaleY={segment.scaleY}
              isInEditingSection={true}
              onDragStart={() => handleDragStart(segment)}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
              onTransformEnd={handleTransformEnd}
              isSelected={selectedSegments.some((s) => s.id === segment.id)}
              isMultiSelected={selectedSegments.length > 1}
              onClick={() => handleSegmentSelect(segment)}
              stageWidth={editingSectionSize.width}
              stageHeight={editingSectionSize.height}
            />
          ))
        : [];
    }, [
      images,
      layers,
      activeLayer,
      selectedSegments,
      editingSectionSize,
      handleDragStart,
      handleDragMove,
      handleDragEnd,
      handleTransformEnd,
      handleSegmentSelect,
    ]);

    return (
      <div
        className={`${className} editing-section relative rounded-lg overflow-hidden`}
        style={{
          ...style,
          background: `
        linear-gradient(45deg, #f0f0f0 25%, transparent 25%),
        linear-gradient(-45deg, #f0f0f0 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #f0f0f0 75%),
        linear-gradient(-45deg, transparent 75%, #f0f0f0 75%)
      `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 0 20px, 20px -20px, -20px 0px',
          boxShadow: `
        inset 0 0 10px rgba(0,0,0,0.2),
        inset 0 0 20px rgba(0,0,0,0.1),
        inset 0 0 30px rgba(0,0,0,0.05)
      `,
        }}
        ref={containerRef}
      >
        <Stage
          ref={stageRef}
          width={editingSectionSize.width}
          height={editingSectionSize.height}
        >
          <Layer>
            {frameProps && <OutFrame ref={frameRef} {...frameProps} />}
          </Layer>
          <Layer>{memoizedSegments}</Layer>
          <Layer>
            <Transformer
              ref={transformerRef}
              boundBoxFunc={(oldBox, newBox) =>
                newBox.width < 5 || newBox.height < 5 ? oldBox : newBox
              }
              rotateEnabled={true}
              enabledAnchors={[
                'top-left',
                'top-right',
                'bottom-left',
                'bottom-right',
              ]}
            />
          </Layer>
        </Stage>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <div ref={toolbarRef}>
            {selectedSegments.length === 1 && (
              <SegmentEditingSection
                images={images}
                setImages={setImages}
                layers={layers}
                activeLayer={activeLayer}
                setLayers={setLayers}
                selectedSegment={selectedSegments[0]}
                setSelectedSegments={setSelectedSegments}
                editingSectionSize={editingSectionSize}
                addOperation={addOperation}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </div>
          {layers.length > 0 && (
            <LayerToolBar
              canUndo={currentOperationIndex > -1}
              canRedo={currentOperationIndex < operations.length - 1}
              onUndo={handleUndo}
              onRedo={handleRedo}
              onFrameToggle={handleFrameToggle}
              onShapeChange={handleShapeChange}
              currentShape={frameShape}
              isFrameActive={isFrameActive}
              scaleIndicator={scaleIndicator}
            />
          )}
        </Suspense>
      </div>
    );
  }
);

export default React.memo(EditingSection);
