import { imageTracer } from 'imagetracer';

export const mockSegmentation = async (inputImageData) => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 1000));

  // Load the image
  const img = new window.Image();
  await new Promise((resolve) => {
    img.onload = resolve;
    img.src = inputImageData;
  });

  const originalWidth = img.width;
  const originalHeight = img.height;

  // Create canvas for the full image
  const canvas = document.createElement('canvas');
  canvas.width = originalWidth;
  canvas.height = originalHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, originalWidth, originalHeight);

  // Function to find bounds of a colored shape
  const findShapeBounds = (imgData, color) => {
    let minX = Infinity,
      maxX = -Infinity,
      minY = Infinity,
      maxY = -Infinity;
    const tolerance = 50; // Color matching tolerance

    for (let y = 0; y < imgData.height; y++) {
      for (let x = 0; x < imgData.width; x++) {
        const i = (y * imgData.width + x) * 4;
        if (
          Math.abs(imgData.data[i] - color[0]) < tolerance &&
          Math.abs(imgData.data[i + 1] - color[1]) < tolerance &&
          Math.abs(imgData.data[i + 2] - color[2]) < tolerance
        ) {
          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
      }
    }

    if (
      minX === Infinity ||
      maxX === -Infinity ||
      minY === Infinity ||
      maxY === -Infinity
    ) {
      return null;
    }

    return {
      x: minX,
      y: minY,
      width: maxX - minX + 1,
      height: maxY - minY + 1,
    };
  };

  const imageData = ctx.getImageData(0, 0, originalWidth, originalHeight);

  // Find bounds for red circle and green square
  const redCircleBounds = findShapeBounds(imageData, [255, 0, 0]); // Red
  const greenSquareBounds = findShapeBounds(imageData, [0, 190, 76]); // Green

  // Create segments with transparent backgrounds
  const createSegment = async (bounds, color) => {
    if (!bounds) return null;

    const segmentCanvas = document.createElement('canvas');
    segmentCanvas.width = bounds.width;
    segmentCanvas.height = bounds.height;
    const segmentCtx = segmentCanvas.getContext('2d');

    // Draw the original image onto the segment canvas
    segmentCtx.drawImage(
      canvas,
      bounds.x,
      bounds.y,
      bounds.width,
      bounds.height,
      0,
      0,
      bounds.width,
      bounds.height
    );

    // Get the image data of the segment
    const segmentImageData = segmentCtx.getImageData(
      0,
      0,
      bounds.width,
      bounds.height
    );

    // Make all pixels that don't match the color transparent
    for (let i = 0; i < segmentImageData.data.length; i += 4) {
      const r = segmentImageData.data[i];
      const g = segmentImageData.data[i + 1];
      const b = segmentImageData.data[i + 2];

      if (
        Math.abs(r - color[0]) > 50 ||
        Math.abs(g - color[1]) > 50 ||
        Math.abs(b - color[2]) > 50
      ) {
        segmentImageData.data[i + 3] = 0; // Set alpha to 0 (transparent)
      }
    }

    // Put the modified image data back onto the canvas
    segmentCtx.putImageData(segmentImageData, 0, 0);

    // Convert PNG to SVG
    const pngDataUrl = segmentCanvas.toDataURL('image/png');
    const svgString = await imageTracer.imageToSVG(pngDataUrl, 'detailed');

    return {
      segmentId: `segment_${color[0]}_${color[1]}_${color[2]}`,
      svgData: svgString,
      boundingBox: {
        x: bounds.x,
        y: bounds.y,
        width: bounds.width,
        height: bounds.height,
      },
    };
  };

  const redSegment = await createSegment(redCircleBounds, [255, 0, 0]);
  const greenSegment = await createSegment(greenSquareBounds, [0, 190, 76]);

  // Create rest image (grayish background)
  const restCanvas = document.createElement('canvas');
  restCanvas.width = originalWidth;
  restCanvas.height = originalHeight;
  const restCtx = restCanvas.getContext('2d');
  restCtx.fillStyle = '#C9C9C9'; // Light gray color
  restCtx.fillRect(0, 0, originalWidth, originalHeight);

  const result = {
    imageId: `mock_image_${Date.now()}`,
    layers: [
      {
        layerId: 'layer_0',
        segments: [redSegment].filter(Boolean),
      },
      {
        layerId: 'layer_1',
        segments: [greenSegment].filter(Boolean),
      },
    ],
    restImage: restCanvas.toDataURL('image/png'),
    originalWidth,
    originalHeight,
  };

  return result;
};
