import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaTrash, FaCopy } from 'react-icons/fa';
import LayerPreview from './LayerPreview';

const LayerSet = ({
  layer,
  isActive,
  onToggleVisibility,
  onDelete,
  onSelect,
  onNameChange,
  onDuplicate,
  layerPreview,
  isOnlyLayer,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(layer.name);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
    onNameChange(layer.id, editedName);
  };

  return (
    <div
      className={`flex items-center p-2 mb-2 cursor-pointer rounded ${
        isActive ? 'bg-blue-100' : 'hover:bg-gray-100'
      }`}
      onClick={onSelect}
    >
      <button
        className="mr-2 focus:outline-none"
        onClick={(e) => {
          e.stopPropagation();
          onToggleVisibility(layer.id);
        }}
      >
        {layer.isVisible ? (
          <FaEye className="text-black" />
        ) : (
          <FaEyeSlash className="text-black" />
        )}
      </button>
      <div className="mr-2 p-0.5 border border-black rounded flex items-center justify-center">
        <div className="w-10 h-10 overflow-hidden">
          <LayerPreview layerPreview={layerPreview} size={40} />
        </div>
      </div>
      {isEditing ? (
        <input
          className="flex-1 bg-transparent border-b border-gray-300 focus:outline-none"
          value={editedName}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          autoFocus
        />
      ) : (
        <div className="flex-1 truncate" onDoubleClick={handleDoubleClick}>
          {layer.name}
        </div>
      )}
      <button
        className="ml-2 focus:outline-none text-black hover:text-gray-700"
        onClick={(e) => {
          e.stopPropagation();
          onDuplicate(layer.id);
        }}
      >
        <FaCopy />
      </button>
      <button
        className={`ml-2 focus:outline-none ${
          isOnlyLayer
            ? 'text-gray-300 cursor-not-allowed'
            : 'text-black hover:text-gray-700'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          if (!isOnlyLayer) onDelete(layer.id);
        }}
        disabled={isOnlyLayer}
      >
        <FaTrash />
      </button>
    </div>
  );
};

export default React.memo(LayerSet);
