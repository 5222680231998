import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, useTexture } from '@react-three/drei';
import {
  EffectComposer,
  Bloom,
  DepthOfField,
  Vignette,
} from '@react-three/postprocessing';
import * as THREE from 'three';
import CutBoard from './CutBoard';
import { useLayerManager } from '../LayerManager';

const TARGET_SIZE = 15;
const MAX_ZOOM_OUT = 40;

const Scene = React.memo(
  ({
    visibleLayers,
    layerPreviews,
    woodTextureUrl,
    managedLayers,
    onLayerProcessed,
    setCutBoardRef,
  }) => {
    const { camera, scene } = useThree();
    const woodTexture = useTexture(woodTextureUrl);

    useEffect(() => {
      camera.position.set(0, TARGET_SIZE, TARGET_SIZE);
      camera.lookAt(0, 0, 0);
      scene.background = new THREE.Color('#f0f0f0');
    }, [camera, scene]);

    return (
      <>
        <ambientLight intensity={0.8} />
        <directionalLight
          position={[0, 5, 0]}
          intensity={1.5}
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        <pointLight position={[0, 5, 0]} intensity={0.8} />
        {visibleLayers.map((layer, index) => (
          <CutBoard
            key={layer.id}
            ref={(el) => setCutBoardRef(layer.id, el)}
            layerId={layer.id}
            layerPreview={layerPreviews[layer.id]}
            position={[0, 0, 0]}
            woodTexture={woodTexture}
            layerIndex={index}
            totalLayers={visibleLayers.length}
            isVisible={true}
            needsUpdate={managedLayers[layer.id]?.needsUpdate}
            isNew={managedLayers[layer.id]?.isNew}
            onProcessed={() => onLayerProcessed(layer.id)}
          />
        ))}
        <mesh
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, -0.5, 0]}
          receiveShadow
        >
          <planeGeometry args={[TARGET_SIZE * 2, TARGET_SIZE * 2]} />
          <shadowMaterial transparent opacity={0.2} />
        </mesh>
      </>
    );
  }
);

const ThreeDView = ({ woodTextureUrl }) => {
  const {
    layers,
    layerPreviews,
    geometryUpdateQueue,
    simpleUpdateQueue,
    clearGeometryUpdateQueue,
    clearSimpleUpdateQueue,
  } = useLayerManager();
  const [managedLayers, setManagedLayers] = useState({});
  const cutBoardRefs = useRef({});

  const visibleLayers = useMemo(
    () => layers.filter((layer) => layer.isVisible),
    [layers]
  );

  const setCutBoardRef = useCallback((layerId, ref) => {
    if (ref) {
      cutBoardRefs.current[layerId] = ref;
    }
  }, []);

   useEffect(() => {
     if (geometryUpdateQueue.length > 0) {
       console.log('Processing geometry update queue:', geometryUpdateQueue);
       const newManagedLayers = { ...managedLayers };

       geometryUpdateQueue.forEach((layerId) => {
         const layer = layers.find((l) => l.id === layerId);
         if (layer && layer.isVisible && layerPreviews[layerId]?.svg) {
           newManagedLayers[layerId] = {
             ...newManagedLayers[layerId],
             needsUpdate: true,
             isNew: !managedLayers[layerId],
           };
        //    if (cutBoardRefs.current[layerId]) {
        //      cutBoardRefs.current[layerId].updateGeometry();
        //    }
         }
       });

       setManagedLayers(newManagedLayers);
       clearGeometryUpdateQueue();
     }
   }, [
     geometryUpdateQueue,
     clearGeometryUpdateQueue,
     layers,
     managedLayers,
     layerPreviews,
   ]);


  useEffect(() => {
    if (simpleUpdateQueue.length > 0) {
      console.log('Processing simple update queue:', simpleUpdateQueue);
      simpleUpdateQueue.forEach((update) => {
        if (update === 'reorder') {
          Object.values(cutBoardRefs.current).forEach((ref) => {
            ref.updatePosition();
          });
        } else if (update.startsWith('delete-')) {
          const layerId = update.split('-')[1];
          setManagedLayers((prev) => {
            const { [layerId]: _, ...rest } = prev;
            return rest;
          });
        }
      });
      clearSimpleUpdateQueue();
    }
  }, [simpleUpdateQueue, clearSimpleUpdateQueue]);

  const handleLayerProcessed = useCallback((layerId) => {
    setManagedLayers((prev) => ({
      ...prev,
      [layerId]: { ...prev[layerId], needsUpdate: false, isNew: false },
    }));
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Canvas
        shadows
        camera={{ position: [0, TARGET_SIZE, TARGET_SIZE], fov: 75 }}
        gl={{
          antialias: true,
          toneMapping: THREE.ACESFilmicToneMapping,
          outputColorSpace: THREE.SRGBColorSpace,
          powerPreference: 'high-performance',
        }}
        performance={{ min: 0.5 }}
      >
        <Scene
          visibleLayers={visibleLayers}
          layerPreviews={layerPreviews}
          woodTextureUrl={woodTextureUrl}
          managedLayers={managedLayers}
          onLayerProcessed={handleLayerProcessed}
          setCutBoardRef={setCutBoardRef}
        />
        <OrbitControls
          enablePan={false}
          enableZoom={true}
          enableRotate={true}
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2}
          maxDistance={MAX_ZOOM_OUT}
        />
        <EffectComposer>
          <DepthOfField
            focusDistance={0.01}
            focalLength={0.02}
            bokehScale={2}
            height={480}
          />
          <Bloom
            intensity={0.1}
            luminanceThreshold={0.9}
            luminanceSmoothing={0.9}
          />
          <Vignette opacity={0.3} darkness={0.7} eskil={false} />
        </EffectComposer>
      </Canvas>
    </div>
  );
};

export default ThreeDView;
