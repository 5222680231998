import { imageTracer } from 'imagetracer';

export const renderLayerToSVG = async (pngDataUrl, width, height) => {
  try {
    const svgString = await imageTracer.imageToSVG(pngDataUrl, 'detailed');

    // Wrap the SVG content in an SVG tag with proper dimensions if it's not already wrapped
    const fullSvgString = svgString.startsWith('<svg')
      ? svgString
      : `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">${svgString}</svg>`;

    return fullSvgString;
  } catch (error) {
    console.error('Error rendering layer to SVG:', error);
    throw error;
  }
};
