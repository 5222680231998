import React, { useState } from 'react';
import { FaPlus, FaShare, FaSpinner } from 'react-icons/fa';
import LayerSet from './LayerSection/LayerSet';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import StatusSection from './StatusSection';
import { useLayerManager } from './LayerManager';

const API_BASE_URL = 'https://api.fabdreamer.art';

const LayerSection = ({
  className,
  style,
  images,
  layers,
  setLayers,
  setActiveLayer,
  activeLayer,
  showAddButton,
  layerPreviews,
  deleteLayer,
  addLayer,
}) => {
  const { duplicateLayer } = useLayerManager();

  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState(null);

  const width = style?.width || 0;
  const showExportText = width >= 300;

  const handleToggleVisibility = (layerId) => {
    setLayers(
      layers.map((layer) =>
        layer.id === layerId ? { ...layer, isVisible: !layer.isVisible } : layer
      )
    );
  };

  const handleDeleteLayer = (layerId) => {
    if (layers.length > 1) {
      deleteLayer(layerId);
      if (activeLayer === layerId) {
        setActiveLayer(layers[0]?.id || null);
      }
    }
  };

  const handleAddLayer = () => {
    const newLayer = {
      id: Date.now(),
      name: `Layer ${layers.length + 1}`,
      isVisible: true,
      segments: [],
      frameStrokeWidth: 50,
      isFrameActive: true,
      frameShape: 'squareOutline',
    };
    addLayer(newLayer);
  };

  const handleLayerNameChange = (layerId, newName) => {
    setLayers(
      layers.map((layer) =>
        layer.id === layerId ? { ...layer, name: newName } : layer
      )
    );
  };

  const canExport = layers.some((layer) => layer.segments.length > 0);

  const handleExportAll = async () => {
    if (canExport && !isExporting) {
      setIsExporting(true);
      setExportError(null);

      try {
        // Package the layer data
        const exportData = layers
          .filter((layer) => layer.isVisible)
          .map((layer) => ({
            name: layer.name,
            preview: layerPreviews[layer.id].png,
          }));

        // Package the raw images data
        const rawImages = images.map((image) => ({
          raw: image.raw
        }));
        console.log('Exporting layers and raw images...', {
          exportData,
          rawImages,
        });

        // Send the data to the backend
        const response = await fetch(`${API_BASE_URL}/export-files`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            layers: exportData,
            raw_images: rawImages,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the blob from the response
        const blob = await response.blob();

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger the download
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'exported_layers.zip';
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        console.log('Export completed successfully');
      } catch (error) {
        console.error('Error exporting layers:', error);
        setExportError('Failed to export layers. Please try again.');
      } finally {
        setIsExporting(false);
      }
    }
  };

  return (
    <div className={className}>
      <StatusSection />
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          {showAddButton && (
            <button
              className="mr-2 p-2 border border-black rounded-md hover:bg-gray-100"
              onClick={handleAddLayer}
            >
              <FaPlus />
            </button>
          )}
          <h2 className="text-sm font-semibold">
            {showAddButton ? 'Add Layers' : 'Layer Previews'}
          </h2>
        </div>
        <button
          className={`flex items-center justify-center p-2 rounded-md ${
            canExport
              ? isExporting
                ? 'bg-yellow-500 text-white cursor-wait'
                : 'bg-green-500 text-white hover:bg-green-600'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          onClick={handleExportAll}
          disabled={!canExport || isExporting}
          title={isExporting ? 'Exporting...' : 'Export All'}
        >
          {isExporting ? (
            <FaSpinner className="animate-spin mr-2" />
          ) : (
            <FaShare className={showExportText ? 'mr-2' : ''} />
          )}
          {showExportText && (isExporting ? 'Exporting...' : 'Export All')}
        </button>
      </div>

      {exportError && <div className="text-red-500 mb-4">{exportError}</div>}

      <Droppable droppableId="layers" mode="vertical" type="DEFAULT">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="space-y-2"
          >
            {layers.map((layer, index) => (
              <Draggable
                key={layer.id}
                draggableId={layer.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                  >
                    <LayerSet
                      layer={layer}
                      isActive={layer.id === activeLayer}
                      onToggleVisibility={handleToggleVisibility}
                      onDelete={handleDeleteLayer}
                      onSelect={() => setActiveLayer(layer.id)}
                      onNameChange={handleLayerNameChange}
                      layerPreview={layerPreviews[layer.id]}
                      isOnlyLayer={layers.length === 1}
                      onDuplicate={duplicateLayer}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default LayerSection;
