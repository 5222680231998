import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Image } from 'react-konva';

const SegmentedEntity = ({
  entity,
  rootEntity,
  scaleX = 1,
  scaleY = 1,
  onDragStart,
  onDragMove,
  onDragEnd,
  onTransformEnd,
  onClick,
  isInEditingSection = false,
  isDragging,
  isSelected = false,
  isMultiSelected = false,
}) => {
  const imageRef = useRef(null);
  const [position, setPosition] = useState({ x: entity.x, y: entity.y });

  useEffect(() => {
    if ((isSelected || isMultiSelected) && imageRef.current) {
      const layer = imageRef.current.getLayer();
      const transformer = layer.findOne('Transformer');
      if (transformer) {
        const currentNodes = transformer.nodes();
        if (!currentNodes.includes(imageRef.current)) {
          transformer.nodes([...currentNodes, imageRef.current]);
          transformer.getLayer().batchDraw();
        }
      }
    }
  }, [isSelected, isMultiSelected]);

  const handleDragStart = useCallback(
    (e) => {
      if (onDragStart) {
        onDragStart(entity, e);
      }
      if (!isInEditingSection) {
        setPosition({ x: e.target.x(), y: e.target.y() });
      }
    },
    [onDragStart, entity, isInEditingSection]
  );

  const handleDragMove = useCallback(
    (e) => {
      if (isInEditingSection && onDragMove) {
        const newPos = { x: e.target.x(), y: e.target.y() };
        onDragMove(e, newPos, entity);
      }
      if (!isInEditingSection) {
        //setPosition({ x: e.target.x(), y: e.target.y() });
        e.target.position(position);
      }
    },
    [isInEditingSection, onDragMove, entity, position]
  );

  const handleDragEnd = useCallback(
    (e) => {
      if (isInEditingSection && onDragEnd) {
        const newPos = { x: e.target.x(), y: e.target.y() };
        onDragEnd(e, newPos, entity);
      }
    },
    [isInEditingSection, onDragEnd, entity]
  );

  const handleTransformEnd = useCallback(
    (e) => {
      if (onTransformEnd) {
        const node = e.target;
        const newProps = {
          x: node.x(),
          y: node.y(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
          rotation: node.rotation(),
        };
        onTransformEnd(e, newProps, entity);
      }
    },
    [onTransformEnd, entity]
  );

  const handleClick = useCallback(
    (e) => {
      e.cancelBubble = true;
      if (onClick) {
        onClick(entity);
      }
    },
    [onClick, entity]
  );

  if (!entity || !entity.image) {
    return null;
  }

  const displayedImage =
    isInEditingSection && entity.styledImage
      ? entity.styledImage
      : entity.image;

  const xPosition = isInEditingSection ? entity.x : position.x * scaleX;
  const yPosition = isInEditingSection ? entity.y : position.y * scaleY;

  return (
    <Image
      ref={imageRef}
      id={entity.id}
      image={displayedImage}
      x={xPosition}
      y={yPosition}
      width={entity.width || entity.originalWidth}
      height={entity.height || entity.originalHeight}
      scaleX={isInEditingSection ? entity.scaleX || 1 : scaleX}
      scaleY={isInEditingSection ? entity.scaleY || 1 : scaleY}
      rotation={entity.rotation || 0}
      draggable={true}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
      onTransformEnd={handleTransformEnd}
      opacity={isDragging ? 0.5 : 1}
    />
  );
};

export default React.memo(SegmentedEntity);
